import { MESSAGE_SHOW, SNACKBAR_HIDE } from '../constants/snackbar';
import { SIGN_IN_ERROR, SIGN_IN_SUCCESS, SIGN_IN_VERIFY_ERROR, SIGN_IN_VERIFY_SUCCESS } from '../constants/signIn';
import {
    CREATE_ORDER_ERROR,
    MY_ORDER_FETCH_ERROR,
    STRIP_SECRET_FETCH_ERROR,
    SUPPORT_ERROR,
    SUPPORT_SUCCESS,
} from '../constants/buyTicket';
import {
    CLAIM_NFT_ERROR,
    ORDERS_FETCH_ERROR,
    PROFILE_DETAILS_FETCH_ERROR,
    TICKET_DOWNLOAD_ERROR,
    TICKET_DOWNLOAD_SUCCESS,
    UPDATE_PROFILE_IMAGE_ERROR,
    UPDATE_PROFILE_SUCCESS,
} from '../constants/myAccount';

const snackbar = (state = {
    open: false,
    message: '',
}, action) => {
    switch (action.type) {
    case MESSAGE_SHOW:
    case SIGN_IN_SUCCESS:
    case SIGN_IN_ERROR:
    case SIGN_IN_VERIFY_ERROR:
    case SIGN_IN_VERIFY_SUCCESS:
    case CREATE_ORDER_ERROR:
    case PROFILE_DETAILS_FETCH_ERROR:
    case MY_ORDER_FETCH_ERROR:
    case ORDERS_FETCH_ERROR:
    case UPDATE_PROFILE_SUCCESS:
    case UPDATE_PROFILE_IMAGE_ERROR:
    case STRIP_SECRET_FETCH_ERROR:
    case TICKET_DOWNLOAD_SUCCESS:
    case TICKET_DOWNLOAD_ERROR:
    case SUPPORT_SUCCESS:
    case SUPPORT_ERROR:
    case CLAIM_NFT_ERROR:
        return {
            open: true,
            message: action.message,
        };
    case SNACKBAR_HIDE:
        return {
            open: false,
            message: '',
        };
    default:
        return state;
    }
};

export default snackbar;
