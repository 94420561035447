const variables = {
    en: {
        home: 'Goto Website',
        buy_tickets: 'Buy Tickets',
        sign_in: 'Sign In',
        my_account: 'My Account',
        edit: 'Edit',
        tickets_sold_out: 'TICKETS SOLD OUT',

        full_name: 'Full Name',
        name_error: 'Name cannot be empty',
        email_address: 'Email Address',
        email_address_error: 'Please enter a valid Email Address',
        company_project: 'Company',
        title: 'FlixFest 2024',
        title_short: 'FlixFest 24',
        tickets_info: 'Tickets Info',
        ticket_type: 'Ticket Type',
        no_of_tickets: 'Total Tickets',
        ticket: 'Ticket',
        checkout: 'Checkout ',
        time_left: 'Time Left',
        contact_info: 'Buyer’s Information',
        send_otp: 'Send OTP',
        contact_info_note: 'Note: Your email is required to send tickets and invoices. You can also login to check your tickets later.',
        otp_sent: 'OTP has been sent to your email for verification. You can resend OTP only after ',
        verify: 'Verify',
        payment_method: 'Payment Method',
        terms: 'I accept the ',
        conditions: 'Terms of service,Community guidelines and Privacy Policy.',
        available_balance: 'Available Balance',
        sign_in_stripe_account: 'Signin to your stripe account to complete your purchase',
        account_name: 'Account Name',
        disconnect: 'Disconnect',
        account: 'Account',
        connect_keplr: 'Connect your Keplr wallet to pay with FLIX token',
        place_order: 'Place Order',
        not_enough_balance: 'Not Enough balance',
        remove: 'Remove',
        connect: 'Connect',

        order_summary: 'Order Summary',
        sales_tax: 'Sales Tax',
        fees: 'Fees',
        discount: 'Discount',
        total: 'Total',
        payment_via: 'Payment via',
        enter_coupon_code: 'Enter coupon code',
        coupon_applied: 'Coupon Applied',
        coupon_expired: 'Coupon Expired',
        coupon_invalid: 'Coupon Invalid',
        no_coupon_added: 'No coupon added!',
        apply: 'Apply',

        confirmation_details: 'Confirmation Details',
        sub_total: 'SubTotal',
        confirm: 'Confirm',
        order_confirmed: 'Order Confirmed',
        order_status: 'We will also send the tickets\n' +
            'to your registered email',
        done: 'Done',

        connect_with_keplr: 'Connect with Keplr',
        tickets: 'Tickets',
        my_tickets: 'My Tickets',
        ordered_tickets: 'Ordered Tickets',
        nfts: 'NFTs',
        orders: 'Orders',
        id: 'ID',
        name: 'Name',
        email: 'Email',
        message: 'Message',
        select_purpose: 'Select Purpose',
        cancel_ticket: 'Cancel Ticket',
        download_ticket: 'Download e-Ticket',
        ordered_on: 'Ordered on',
        claim: 'Claim',
        claim_nft: 'Claim NFT',
        connecting: 'Connecting',

        email_content: 'Sign in to access your tickets and order history',
        sent_otp: 'Sent OTP',
        otp_content: 'OTP sent to your registered Email',
        otp_valid: 'OTP is valid for ',
        resend: 'Resend',
        valid_email: 'Enter Valid Email Address',
        check_later: 'Transaction is in process. check after some time.',
        approval_pending: 'Approval Pending',
        processing: 'Processing',
        switch_account: 'Switch account in keplr and connect to update',
        payment_gateway_error: 'Payment gate way error Try after sometime',

        your_request_time_expired: 'Your request time is expired.',
        exit: 'Exit',
        tickets_limit: 'Tickets limit exceeded',

        cancel_confirmation: 'Cancel Tickets Confirmation',
        stripe_content: 'Pay with your Credit or Debit Card',
        keplr_content_disabled: 'Currently, crypto payments are only supported via desktop browsers with Keplr Extension',
        keplr_content: 'Pay with crypto. Connect your Keplr wallet to complete the purchase.',
        keplr_content1: 'Supported Tokens: FLIX',
        ticket_status: 'Ticket Status',
        order_id: 'Order ID',
        ticket_id: 'Ticket ID',
        powered_by: 'Powered by',
        get_in_touch: 'Get in touch',
        address1: 'Lock, Stock & Barrel, Business Bay, Dubai, UAE',
        verify_captcha: 'Please check the box below to proceed',
        your_ticket: 'Your Ticket',
        resume: 'Resume',
        cancel: 'Cancel',
        add_tickets: 'Add Tickets',
        in_active_ticket_sale: 'In-active ticket sale',
        thank_you_contacting: 'Thank you for contacting us. Our team will get back to you shortly.',
        close: 'Close',
        order_confirmed_text: 'We will send your tickets to your registered email',
        share_tweet: 'Share',
        tweet_text: 'Got my 🎟 to #FlixFest2024 at #Cosmoverse24\n\n',
        tweet_text2: 'Event partners👀\n',
        tweet_tags: 'Showstopper: @cosmoshub\n',
        tweet_tags2: 'Gamechanger: @StreamSwap_io @ArkaNetwork_ @stakecito\n',
        tweet_tags3: 'Trendsetter: @madscientists_x @POSTHUMAN_DVS @DCF_io\n',
        tweet_tags4: 'Community: @osmosiszone @akashnet_ @HuginnStake\n\n',
        tweet_text3: "Can't wait!🚀 \n\n",
        tweet_link: 'https://ff24.layerzerox.com/buyTickets \n\n',
        tweet_content: '#FlixFest2024 #Cosmoverse #OmniFlix @OmniFlixNetwork ',
        view_tickets: 'View Tickets',
        sold_out: 'SOLD OUT',
        upcoming: 'UPCOMING',
        late_pass_content: 'Tickets will go live on ',

        // connect dialog
        cosmostation: 'Cosmostation',
        leap: 'Leap',
        keplr_wallet: 'Keplr Wallet',
        keplr: 'Keplr',
        wallet_connect: 'Wallet Connect',
        update: 'Update',
    },
};

export default variables;
