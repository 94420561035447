export const EMAIL_SET = 'EMAIL_SET';
export const OTP_SET = 'OTP_SET';

export const SIGN_IN_DIALOG_SHOW = 'SIGN_IN_DIALOG_SHOW';
export const SIGN_IN_DIALOG_HIDE = 'SIGN_IN_DIALOG_HIDE';

export const SIGN_IN_IN_PROGRESS = 'SIGN_IN_IN_PROGRESS';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR';

export const SIGN_IN_VERIFY_IN_PROGRESS = 'SIGN_IN_VERIFY_IN_PROGRESS';
export const SIGN_IN_VERIFY_SUCCESS = 'SIGN_IN_VERIFY_SUCCESS';
export const SIGN_IN_VERIFY_ERROR = 'SIGN_IN_VERIFY_ERROR';

export const ACCESS_TOKEN_FETCH_IN_PROGRESS = 'ACCESS_TOKEN_FETCH_IN_PROGRESS';
export const ACCESS_TOKEN_FETCH_SUCCESS = 'ACCESS_TOKEN_FETCH_SUCCESS';
export const ACCESS_TOKEN_FETCH_ERROR = 'ACCESS_TOKEN_FETCH_ERROR';
