import { combineReducers } from 'redux';
import { HIDE_SIDE_BAR_SET, PROFILE_POPOVER_HIDE, PROFILE_POPOVER_SHOW, SHOW_SIDE_BAR_SET } from '../constants/navBar';

const show = (state = false, action) => {
    switch (action.type) {
    case SHOW_SIDE_BAR_SET:
        return true;
    case HIDE_SIDE_BAR_SET:
        return false;
    default:
        return state;
    }
};

const profilePopover = (state = {
    open: false,
    anchorEl: null,
}, action) => {
    switch (action.type) {
    case PROFILE_POPOVER_SHOW:
        return {
            open: true,
            anchorEl: action.anchorEL,
        };
    case PROFILE_POPOVER_HIDE:
        return {
            open: false,
            anchorEl: null,
        };
    default:
        return state;
    }
};

export default combineReducers({
    show,
    profilePopover,
});
