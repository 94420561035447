import { HIDE_SIDE_BAR_SET, PROFILE_POPOVER_HIDE, PROFILE_POPOVER_SHOW, SHOW_SIDE_BAR_SET, UPDATE_BC_ACCOUNT_ERROR, UPDATE_BC_ACCOUNT_IN_PROGRESS, UPDATE_BC_ACCOUNT_SUCCESS } from '../constants/navBar';
import { urlUpdateBCAccount } from '../constants/url';
import Axios from 'axios';

export const showSideBar = () => {
    return {
        type: SHOW_SIDE_BAR_SET,
    };
};

export const hideSideBar = () => {
    return {
        type: HIDE_SIDE_BAR_SET,
    };
};

export const showProfilePopover = (event) => {
    return {
        type: PROFILE_POPOVER_SHOW,
        anchorEL: event.currentTarget,
    };
};

export const hideProfilePopover = () => {
    return {
        type: PROFILE_POPOVER_HIDE,
    };
};

const updateBCAccountInProgress = () => {
    return {
        type: UPDATE_BC_ACCOUNT_IN_PROGRESS,
    };
};

const updateBCAccountSuccess = (message, value) => {
    return {
        type: UPDATE_BC_ACCOUNT_SUCCESS,
        message,
        value,
    };
};

const updateBCAccountError = (message) => {
    return {
        type: UPDATE_BC_ACCOUNT_ERROR,
        message,
        variant: 'error',
    };
};

export const updateBCAccount = (data, cb) => (dispatch) => {
    dispatch(updateBCAccountInProgress());

    const url = urlUpdateBCAccount();
    Axios.put(url, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_flix_fest_24'),
        },
    })
        .then((res) => {
            dispatch(updateBCAccountSuccess('Success'));
            if (cb) {
                cb(null);
            }
        })
        .catch((error) => {
            dispatch(updateBCAccountError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));

            if (cb) {
                cb(error);
            }
        });
};
